/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-gray-darkest: #2d2d2d;
$c-gray-darker: #5e5e5e;
$c-gray-dark: rgb(49, 53, 62);
$c-gray-medium: rgb(166, 171, 175);
$c-gray: #b2b2b2;
$c-gray-light: hsl(210, 2%, 82%);
$c-gray-lighter: #e6e6e6;
$c-gray-lightest: #f5f5f5;
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;
// $c-red: #f00;
// $c-green: #17b603;
// $c-lemon: #f8e71c;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #eb5757;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-mustard: rgb(252, 175, 23);
$c-dark-grey: rgb(49, 53, 62); // #31353e
$c-medium-grey: rgb(166, 171, 175); // #a6abaf
$c-light-grey: rgb(207, 208, 209); // #cfd0d1
$c-lighter-grey: rgb(234, 234, 234); //#eaeaea

$c-facebook: rgb(66, 103, 178);
$c-twitter: rgb(29, 161, 242);
$c-linkedin: rgb(14, 118, 168);

/*
* Dimensions
*/
$dynamic-100vh: calc(
  #{var(--vh, 1vh)} * 100
); // Act like 100dvh but works in every browser
$content-width: 1440px;
$header-breakpoint: xl;
$menu-desktop-height: 900px;
$mbb-logo-width-xxs: 15rem;
$mbb-logo-width-xs: 20rem;
$mbb-logo-width: 25rem;

/*
* Typography
*/

$base-font-size: 10px !default;

$ff-default: 'KievitOT', sans-serif;
$ff-alt: 'CirculatStd', sans-serif;
$body-text: 1.6rem;
$body-text-m: 1.8rem;

$font-sizes: (
  h1: 3.6rem,
  h2: 2.8rem,
  h3: 2.4rem,
  h4: 1.4rem,
);

/**
* RWD
*/
$mq-responsive: true;
$mq-static-breakpoint: 'large';

/**
* RWD
*/

$spacing: 2rem;

$ease-80-20: cubic-bezier(0, 0.91, 0, 1);
$ease-softer: cubic-bezier(0, 0.6, 0.47, 1);

/**
* Shadows
*/
$card-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);
