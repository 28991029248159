
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.contact-grid {
  @include mq(m) {
    display: flex;
    flex-wrap: wrap;
  }
}

.contact-grid__item {
  @include mq($until: m) {
    margin-top: $spacing * 1.5;
  }

  @include mq(m) {
    flex-basis: calc(calc(100% - #{$spacing * 1.5}) / 2);

    &:nth-child(2n) {
      margin-left: $spacing * 1.5;
    }

    &:nth-child(3n) {
      flex-direction: row;
      align-items: center;
      flex-basis: 100%;
      min-height: 0;
      margin-top: $spacing * 1.5;
    }
  }
}
