
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










.contact {
  padding-top: $spacing * 4;

  @include mq(m) {
    padding-top: $spacing * 5;
  }

  @include mq(xl) {
    padding-top: $spacing * 8;
  }
}

.contact__title {
  @extend %visually-hidden;
}

.contact__faq-section {
  @include default-margin;
}

.contact__ctas {
  @include default-margin;
}
