@use 'sass:math';

@mixin center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin get-all-space {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin image-fit($fit: cover, $position: null) {
  @include get-all-space;

  object-fit: $fit;

  @if $position {
    object-position: $position;
  }
}

@mixin owl($margin: false) {
  > :first-child {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }
}

@mixin fluid-type($from, $to, $until: xxl) {
  font-size: calc(
    #{$from}px + (#{$to} - #{$from}) *
    (
      (100vw - #{mq-get-breakpoint-width('xxs')}) /
      (
        #{strip-unit(mq-get-breakpoint-width($until))} - #{strip-unit(
          mq-get-breakpoint-width(xxs)
        )}
      )
    )
  );

  @include mq(xxl) {
    font-size: #{$to}px;
  }
}

@mixin fluid($property, $map) {
  $finalmap: ();

  @each $breakpoint, $value in $map {
    $nameToPx: map-get($mq-breakpoints, $breakpoint);

    @if $nameToPx {
      $finalmap: map-merge(
        $finalmap,
        (
          $nameToPx: $value,
        )
      );
    } @else {
      $finalmap: map-merge(
        $finalmap,
        (
          $breakpoint: $value,
        )
      );
    }
  }

  @include poly-fluid-sizing($property, $finalmap);
}

@mixin default-margin($coef: 1) {
  margin-top: $spacing * 3 * $coef;

  @include mq(m) {
    margin-top: $spacing * 4 * $coef;
  }

  @include mq(l) {
    margin-top: $spacing * 5 * $coef;
  }
}

@mixin numbered-list-item($slug, $size: 4rem) {
  display: flex;
  align-items: baseline;

  &::before {
    @extend %ff-alt;

    content: counter($slug);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: $size;
    aspect-ratio: 1;
    margin-right: $spacing;
    text-align: center;
    border: 0.1rem solid $c-mustard;
    border-radius: 50%;
  }
}

/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Kitty Giraudel
/// @param {Length} $size - Horizontal height and vertical width
/// @param {Color} $foreground-color - color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars(
  $size: 6px,
  $foreground-color: $c-black,
  $background-color: $c-white
) {
  // sass-lint:disable-block no-vendor-prefixes
  // For Google Chrome
  &::-webkit-scrollbar {
    position: absolute;
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
