
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















.contact-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 20rem;
  padding: $spacing * 2;
  border: 1px solid $c-gray-light;
  border-radius: 0.8rem;
  transition: border-color 0.3s $ease-out-quad;

  &::after {
    @include get-all-space;

    content: '';
    z-index: -1;
    display: block;
    background-color: $c-mustard;
    transform: scaleX(0);
    border-radius: 0.8rem;
    transform-origin: right;
    transition: transform 0.3s $ease-out-quad;
  }

  &:hover {
    border-color: transparent;

    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  @include mq(m) {
    min-height: 25rem;
    gap: $spacing * 2;
  }
}

.contact-card__title {
  transition: color 0.4s $ease-out-quad;

  .contact-card:hover & {
    color: $c-white;
  }
}

.contact-card__link {
  @include get-all-space;

  z-index: 9;
}

.contact-card__icon {
  width: 6rem;
  height: 3rem;
  fill: $c-mustard;
  transition: 0.3s $ease-out-quad;
  transition-property: transform, fill;

  .contact-card:hover & {
    fill: $c-white;
    transform: translateX(20%);
  }

  @include mq($until: m) {
    margin-top: $spacing;
  }
}
